import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import Backend from 'i18next-http-backend';
import global_en from './locales/en.json'
import global_it from './locales/it.json'
import LanguageDetector from 'i18next-browser-languagedetector';
import datiInit from './util/datiInit';
i18n
// if you want your translations to be loaded from a professional CDN
 // .use(Backend)
  // to detect user language
  .use(LanguageDetector)
  // to pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
.init({
    fallbackLng: datiInit.getInstance().getLingua(),
    debug: true,

    resources: {
        en: {
          translation: global_en,
        },
        it: {
          translation: global_it,
        },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  }

});

export default i18n;

